@import '../../variables/variables.less';
@import '../../style-guide/components/platform-switcher.less';

.top {
    padding-top: 64px;
    padding-bottom: 88px;
    @media @mobile {
        padding-bottom: 64px;
    }

    .container {
        text-align: center;

        h1 {
            font-size: 56px;
            margin-bottom: 24px;
        }

        .sub-h1 {
            margin-bottom: 32px;
        }

        .button-wrapper {
            .button {
                margin-bottom: 0;
                &:not(.last) {
                    margin-right: 28px;

                    @media @mobile {
                        margin-right: 12px;
                    }
                }

                @media @mobile {
                    margin-bottom: 24px;
                }

                .icons {
                    background-position: 28% 46%;
                    background-size: 16px 16px;
                    top: calc(50% - 11px);

                    @media @mobile {
                        background-position: 26% 46%;
                    }
                }

                span {
                    color: @Blue-v2;
                    border-color: @Blue-v2;
                    padding-left: 55px;
                    font-weight: 500;

                    &:hover {
                        color: @White;
                    }
                }

                &.icon-home > SPAN {
                    background-image: url("../../../i/error-page/icon-home.svg");
                    &:hover {
                        background-image: url("../../../i/error-page/icon-home.png");
                    }
                    .icons;
                }
                &.icon-store > SPAN {
                    background-image: url("../../../i/error-page/icon-store.svg");
                    &:hover {
                        background-image: url("../../../i/error-page/icon-store.png");
                    }
                    .icons;
                }
                &.icon-support > SPAN {
                    background-image: url("../../../i/error-page/icon-support.svg");
                    &:hover {
                        background-image: url("../../../i/error-page/icon-support.png");
                    }
                    .icons;
                    background-position: 24% 48%;
                    @media @mobile {
                        background-position: 24% 48%;
                    }
                }
            }
        }
    }
}

.section-product {
    padding-top: 88px;
    padding-bottom: 0;
    @media @mobile {
        padding-top: 0;
    }
    @media @tablet {
        padding-top: 44px;
    }

    .content {
        @media @mobile {
            text-align: center;
            margin-top: 48px;
        }

        .product-title {
            margin-bottom: 16px;

            span {
                font-size: 14px;
                color: @GreenPrimary-v2;
                font-weight: 700;
                margin-left: 8px;
                text-transform: uppercase;
              }
        }

        h2 {
            margin-bottom: 24px;
        }

        .subh2 {
            margin-bottom: 32px;
        }

        .button {
            margin-bottom: 32px;
        }
    }
}
